import { serialize, parse } from 'cookie';
import { parseDomain } from "parse-domain";

const TOKEN_NAME = 'session';
const MAX_AGE = 60 * 60 * 24 * 365; // 1 an

export function setTokenCookie(req, res, token) {

    //console.log(3333);
    //console.log(req.headers.host);
    //console.log(parseDomain(req.headers.host));
    //console.log(parseDomain('breguet.elior.app'));

    /*
    if(req && req.headers && req.headers.host) {
        const d = parseDomain(req.headers.host);
        // const d = parseDomain('breguet.elior.app');
        if(d && d.type && d.type === 'LISTED') {
            if(d.domain && d.topLevelDomains && d.topLevelDomains.length === 1) {
                const c = `.${d.domain}.${d.topLevelDomains[0]}`;
                console.log(c);

                const cookie = serialize(TOKEN_NAME, token, {
                    maxAge: MAX_AGE,
                    expires: new Date(Date.now() + MAX_AGE * 1000),
                    httpOnly: true,
                    secure: process.env.NODE_ENV === 'production',
                    path: '/',
                    sameSite: 'lax',
                    domain: c
                })
                res.setHeader('Set-Cookie', cookie);
                return;
            }
        }
    }
    */

    const cookie = serialize(TOKEN_NAME, token, {
        maxAge: MAX_AGE,
        expires: new Date(Date.now() + MAX_AGE * 1000),
        httpOnly: true,
        //secure: process.env.NODE_ENV === 'production',
        path: '/',
        //sameSite: 'lax',
    })

    res.setHeader('Set-Cookie', cookie)
}

export function removeTokenCookie(res) {
    const cookie = serialize(TOKEN_NAME, '', {
        maxAge: -1,
        path: '/',
    })

    res.setHeader('Set-Cookie', cookie)
}

export function parseCookies(req) {
    // For API Routes we don't need to parse the cookies.
    if (req && req.cookies) return req.cookies

    // For pages we do need to parse the cookies.
    const cookie = req.headers?.cookie
    return parse(cookie || '')
}

export function getTokenCookie(req) {
    const cookies = parseCookies(req)
    return cookies[TOKEN_NAME]
}

export function reset(res) {

    console.log('RESET');
    console.log(process.env.NODE_ENV);

    /*
    const cookie = serialize(TOKEN_NAME, '', {
        maxAge: 0,
        expires: new Date(Date.now() - 100000),
        httpOnly: true,
        secure: process.env.NODE_ENV === 'production',
        path: '/',
        sameSite: 'lax',
    })

    res.setHeader('Set-Cookie', cookie)
    */

    res.setHeader('Set-Cookie', [
        serialize(TOKEN_NAME, '', {
          maxAge: -1,
          path: '/',
        }),
      ]);
    

}
