export const getWidth = () => {

    if(typeof document === 'object') {
        return document.body.clientWidth;
    }

    return 0;
    
};
  
export default getWidth;
