/* global fetch */

import {
    all,
    call,
    delay,
    fork,
    put,
    select,
    take,
    takeEvery,
    takeLatest
} from "redux-saga/effects";
import { push } from 'react-router-redux';

import es6promise from "es6-promise";
import "isomorphic-unfetch";
import { getSession } from '~/src/lib/iron'
import Router from 'next/router';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import {
    CART_PRODUCT_ADD,
} from "./common/constants";

import {
    COOKIE1,
    COOKIE2,
    COOKIE_QRCODE1_DURATION,
    COOKIE_QRCODE2_DURATION
} from "~/src//config/constants";

import { parseCookies, setCookie, destroyCookie } from "nookies";


import setLocale from "~/src/lib/common/utils/setLocale";
import findWhere from "~/src/lib/common/utils/findWhere";
import isMobile from "~/src/lib/isMobile";
import checkMaintenance from "~/src/lib/checkMaintenance";


import postNativeApp from '~/src/lib/postNativeApp';
import queryString from "query-string";

import {
    convertIconNameToNumber,
    getCompliantNameForAProduct
} from "~/src/lib/common/regime/regime";


import messageService from "~/src/lib/req/message";
import toast from "~/src/lib/toast";

import getCartTotalForMerchant from "~/src/lib/common/order/getCartTotalForMerchant";

import getDomain from '~/src/lib/getDomain';
import getLangV2 from '~/src/lib/getLang';

import { initFirestore } from "~/src/lib/firebase/firestore";

import { DateTime } from "luxon";

import actionsRestaurant from "~/redux/restaurant/actions";
import actionsCommon from "~/redux/common/actions";
import actionsLang from "~/redux/lang/actions";
import actionsApp from "~/redux/app/actions";
import actionsUser from "~/redux/user/actions";
import actionsOrder from "~/redux/order/actions";


import {
    LOAD_RESTAURANT,
    LOAD_RESTAURANT_SUCCESS,
    UPDATE_RESTAURANT,
} from "~/redux/restaurant/constants";

import {
    SET_LANG
} from "~/redux/common/constants";


import {FETCH_ORDER} from "~/redux/order/constants";

import config from "~/src/config";


es6promise.polyfill();




const getApp = state => state.app;
const getCommon = state => state.common;
const getLang = state => state.lang;
const getRestaurant = state => state.restaurant;
const getUser = state => state.user;

function* _handleQrcode() {
    const common = yield select(getCommon);
    const app = yield select(getApp);
    const restaurant = yield select(getRestaurant);
   
    const parsed = queryString.parse(document.location.search);

    console.log('_handleQrcode');
    console.log(parsed);

    //1. S'il y a une info de QR Code dans l'URL :

    if(parsed.qrcode) {
        const matchQrcode = restaurant.qrcodes.find((q) => q.code === parsed.qrcode);
        console.log(matchQrcode);

        if( matchQrcode && matchQrcode.type === 'table') {
            yield put(actionsApp.setQrcodeInfo(parsed.qrcode));
            yield put(actionsCommon.setMenuType('inside'));
            Cookies.set(COOKIE1, parsed.qrcode, { expires: COOKIE_QRCODE1_DURATION/86400 });
            Cookies.set(COOKIE2, parsed.qrcode, { expires: COOKIE_QRCODE2_DURATION/86400 });           
        }
    } else {

        // S'il n'y a pas d'info QR code dans l'URL :
        /*
            2.a. Si Cookie 1 existe et Cookie 2 existe
            - On prolonge cookie 2 de 1h. Donc l'user peut commander à table pendant 1h supplémentaire à partir de l'heure d'affichage de la page, dans la limite de validité de cookie 1.
        */
        const qrcode1 = Cookies.get(COOKIE1);
        const qrcode2 = Cookies.get(COOKIE2);
      
        //console.log('AAAAAAA');
        //console.log(qrcode1);
        //console.log(typeof qrcode1);
        //console.log(qrcode2);
        //console.log(typeof qrcode2);
     
        if(typeof qrcode1 === 'string' && typeof qrcode2 === 'string') {
            
            //console.log('HHHHHHHHHH');

            const matchQrcode = restaurant.qrcodes.find((q) => q.code === qrcode1);

            if( matchQrcode && matchQrcode.type === 'table') {
                Cookies.set(COOKIE2, qrcode1, { expires: COOKIE_QRCODE2_DURATION/86400 });
                yield put(actionsApp.setQrcodeInfo(qrcode1));
                yield put(actionsCommon.setMenuType('inside'));  

                const menu = restaurant.menusV2.find((q) => q.typeMenu === 'inside'); 
                if(menu) {
                    yield put(actionsApp.setSelectedMenu(menu.id));
                }
            }


            return;
        }

        /*
            2.b. Si cookie 1 existe et Cookie 2 n'existe pas
            - On affiche l'écran de confirmation dans la limite de validité de cookie 1.
            - Si l'user répond 'Oui je suis présent' on sette cookie 2 pour 1h à partir de ce moment et il peut commander pour une heure de plus dans la limite de validité de cookie 1
            - Si l'user répond 'Non, je suis parti', on supprime cookie 1 et Cookie 2. Il n'y aura plus d'affichage de l'écran de confirmation, il faudra re-scanner le QR code pour commander.
        */
       

        
    }


}

function* loadRestaurantSuccessSaga(action) {

    console.log('LOAD_RESTAURANT_SUCCESS - LOAD_RESTAURANT_SUCCESS');
    //console.log(action.payload.lang);
    //console.log(action.payload.data.info.uuid);


    yield put(actionsLang.setLang(action.payload.lang));
    setLocale(action.payload.lang);



    
    /*
    setCookie(null, 'NEXT_LOCALE', action.payload.lang, {
        maxAge: 30 * 24 * 600 * 600,
        path: '/',
    });
    */

}


function* loadRestaurantSaga(action) {

    console.log(11111122);
    //console.log(action);
    const {lang, req, data} = action.payload;
    console.log(lang);
    //console.log(data);

    /*  si le restaurant a déja eté chargé, on fait rien (ne se produit pas normalement)    */
    const restaurant = yield select(getRestaurant);
    if (Object.keys(restaurant).length > 0) {
        return false;
    }



    





    if (req && req.url) {
        if (req.url === '/booking') {
            yield put(actionsApp.setActiveTab('booking'));
        } else if (req.url === '/social') {
            yield put(actionsApp.setActiveTab('social'));
        } else if (req.url === '/list') {
            yield put(actionsApp.setActiveTab('list'));
        }
    }

  
    try {

        yield put(actionsRestaurant.loadRestaurantSuccess({ data: data, lang: lang }));
        yield put(actionsApp.setIp(req.headers['x-forwarded-for']));



    
        let setMenu = false;
        if (req.url.indexOf("/m/") !== -1) {
            
            const tmp = req.url.split('/');

            if (tmp[2]) {
                const tmp1 = tmp[2].split('?');
                const slug = tmp1[0];
                const match = data.menusV2.find((o) => o.slug === slug);

                if (match) {
                    console.log(match);
                    setMenu = true;
                    console.log(match.id);
                    yield put(actionsApp.setSelectedMenu(match.id));
                }
            }
        }

        if (setMenu === false && data && data.menusV2 && data.menusV2[0] && data.menusV2[0].id) {
            yield put(actionsApp.setSelectedMenu(data.menusV2[0].id));
        }

        const session = yield call(getSession, action.payload.req);

        if (session && session.token && data && data.info && data.info.uuid) {
            const userUrl = '/user?restaurant_uuid='+data.info.uuid;
            const result = yield call(messageService.get, userUrl , true, session.token);

            //console.log(1111);
            //console.log(result);



            if (result && result.data) {

                if(result.data.customerRestaurants && data && data.info && data.info.id) {
                    const match = result.data.customerRestaurants.find((o) => o.restaurant_id === data.info.id);
                    if(match) {
                        //console.log(12345);
                        //console.log(match);

                        session.confirmed = match.confirmed;
                        session.customerRestaurant = match;
                    } else {
                        session.confirmed = 0;
                    }
                }

                session.created_at = result.data.created_at;
                session.email = result.data.email;
                session.roles = result.data.roles;

                if (typeof result.data.orders === 'number') {
                    session.orders = result.data.orders;
                }
            }

            yield put(actionsUser.setUser(session));
        }
    } catch (err) {
        console.log(err);
    }

}



function* updateRestaurantSaga(action) {

    const domain = getDomain(document.location.host);
    const url = `${config.jsonUrl}/${domain}-${action.payload}.json`;

    try {
        const res = yield fetch(url);
        const data = yield res.json();

        yield put(actionsRestaurant.setRestaurant(data));
    } catch (err) {
        console.error(err);
        // yield put(failure(err));
    }
}

function* onRehydrateSaga() {
    const common = yield select(getCommon);
    const app = yield select(getApp);
    const restaurant = yield select(getRestaurant);
    const lang = yield select(getLang);
    
    console.log('onRehydrateSaga');
    
    
    if (restaurant && restaurant.info && typeof restaurant.info.img_layout === 'string' && restaurant.info.img_layout.length > 2 && isMobile() === false) {
        const url = `${config.assetsUrl}/img/layout/${restaurant.info.img_layout}`;
        document.body.style.backgroundImage = "url('" + url + "')";
        document.body.style.backgroundPosition = "center center";
        document.body.style.backgroundAttachment = "fixed";
    }
    
   

    const locale1 = yield call(messageService.get, `https://assets.foound.io/i18n/webapp/${lang}.json?t=${Date.now()}`);
    //console.log(locale1);
    if(locale1 && locale1.data && locale1.data.messages) {
        //window.foound_webapp = JSON.stringify(locale1.data.messages);
        localStorage.setItem("foound_webapp", JSON.stringify(locale1.data.messages));
    }


    const locale2 = yield call(messageService.get, `https://assets.foound.io/i18n/universal/${lang}.json?t=${Date.now()}`);
    //console.log(locale2);
    if(locale2 && locale2.data && locale2.data.messages) {
        //window.foound_universal = locale2.data.messages;
        localStorage.setItem("foound_universal", JSON.stringify(locale2.data.messages));
    }

    const locale3 = yield call(messageService.get, `https://assets.foound.io/i18n/bib/${lang}.json?t=${Date.now()}`);
    //console.log(locale3);
    if(locale3 && locale3.data && locale3.data.messages) {
        //window.foound_bib = locale3.data.messages;
        localStorage.setItem("foound_bib", JSON.stringify(locale3.data.messages));
    }

    if (restaurant && restaurant.info && restaurant.info.uuid === 'be4bed13-fcbf-4021-8be9-d10937455742') {
        yield put(actionsCommon.setEliorMenuType());
    }


    if(restaurant && restaurant.babel_traductions) {
        //Cookies.set('babel_traductions', JSON.stringify(restaurant.babel_traductions), { expires: 365   });
        localStorage.setItem("babel_traductions", JSON.stringify(restaurant.babel_traductions));
    }




    initFirestore(app.domain, lang);

    if (restaurant && restaurant.info && restaurant.info.currency) {
        if (common.currency === false) {
            yield put(actionsCommon.setCurrency(restaurant.info.currency));
        }
    }
    
     

    yield call(_handleQrcode);

    if (restaurant && restaurant.info && checkMaintenance(restaurant, app.ip) === true) {
        yield call(Router.push, `/${lang}/maintenance`);
    }

    const sess = Cookies.get('sess');
    if(sess) {
        Cookies.set('sess', sess, { expires: 365 });
    } else {
        Cookies.set('sess', uuidv4(), { expires: 365 });
    }

    /*
    try {
        // custom trad
        if (restaurant && restaurant.info) {
            const url = `${config.assetsUrl}/i18n/custom/${lang}_${restaurant.info.uuid}.json`;
            const customTradRes = yield fetch(url);

            console.log(url);
            console.log(customTradRes.status);

            if (customTradRes.status === 200) {

                const customTrad = yield customTradRes.json();
                if(customTrad && customTrad.messages) {
                window.customMessages = customTrad.messages;
                }
                console.log(customTrad);
            }
        }
    } catch (err) {
        console.log('error custom trad');
        console.error(err);
        // yield put(failure(err));
    }
    */
    


    try {
        yield call(fetchOrder);
    } catch (err) {
        console.log('error fetchOrder');
        console.error(err);
        // yield put(failure(err));
    } 

}










function* loginSuccess() {

    const restaurant = yield select(getRestaurant);
    const app = yield select(getApp);
    const user = yield select(getUser);

}

function* logout() {

    console.log('saga logout');
    const result = yield call(fetch, '/api/logout');
    //return false;

    //console.log(result);

    //const restaurant = yield select(getRestaurant);
    //const app = yield select(getApp);
  
    const lang = yield select(getLang);

    setTimeout(() => {
        document.location.href = `/${lang}/login`;
    }, 700);



}


function* setLang(action) {

    console.log('AAAAAAA');
    console.log(action);

    setCookie(null, 'NEXT_LOCALE', action.payload, {
        maxAge: 30 * 24 * 600 * 600,
        path: '/',
    })


}



function* setUser(action) {
    postNativeApp();
}

function* onCartAdd() {

    console.log('ON CART ADD');
    const app = yield select(getApp);
    console.log(app);


}






function* fetchOrder(action) {

    console.log('SAGA FETCH ORDER');
    const restaurant = yield select(getRestaurant);
    const user = yield select(getUser);

    if(restaurant && restaurant.info) {
        if(!user) {
            const result = yield call(messageService.get, `/user/anonymous_order/${restaurant.info.uuid}/${Cookies.get('sess')}`);
            console.log('SAGA RESULT FETCH ORDER');
            console.log(result);
            if(result && result.result === true && result.data) {
                yield put(actionsOrder.setOrder(result.data));
            }
        } else {
            const result = yield call(messageService.get, `/user/order?restaurant_uuid=${restaurant.info.uuid}`, true);
            console.log('SAGA RESULT FETCH ORDER');
            console.log(result);
            if(result && result.result === true && result.data) {
                yield put(actionsOrder.setOrder(result.data));
            }
        }
    }

}



function* rootSaga() {
    yield all([
        //takeLatest(LOAD_RESTAURANT, loadRestaurantSagaOld),
        takeLatest(LOAD_RESTAURANT, loadRestaurantSaga),
        takeLatest(LOAD_RESTAURANT_SUCCESS, loadRestaurantSuccessSaga),
        takeLatest('LOGIN_SUCCESS', loginSuccess),
        takeLatest('LOGOUT', logout),
        takeLatest('UPDATE_RESTAURANT', updateRestaurantSaga),
        takeLatest(CART_PRODUCT_ADD, onCartAdd),
        takeLatest(FETCH_ORDER, fetchOrder),
        takeLatest('SET_USER', setUser),
        takeLatest('SET_LANG', setLang),
        takeLatest('persist/REHYDRATE', onRehydrateSaga),
    ]);
}

export default rootSaga;
