import isOptionValueAvailable from "~/src/lib/v2/option/isOptionValueAvailable";
import isOptionValueActive from "~/src/lib/v2/option/isOptionValueActive";

export const isHandleOptionValue = (restaurant, optionValue, common, qrcode, menuType, selectedRegimes) => {
    let response = true;

    //console.log('optionValue.type');
    //console.log(optionValue.type);

    if (
        optionValue.type !== 1 && 
        optionValue.restaurant_product_id &&
        optionValue.restaurant_product_id > 0
    ) {


        //https://trello.com/c/z4sD3NJZ/1082-h%C3%A9ritage-de-loption

        if(isOptionValueActive(restaurant, optionValue) === false) {
            response = false;
        }

        if(isOptionValueAvailable(restaurant, optionValue) === false) {
            response = false;
        }
    
      

        if (+restaurant.info.handle_price_takeaway === 1 && 
            optionValue && 
            optionValue[`${menuType}_status`] === 0 &&
            typeof optionValue[`${menuType}_status`] === "number") {
                response = false;
          
        }    

        if (+restaurant.info.handle_price_delivery === 1 && 
            optionValue && 
            optionValue[`${menuType}_status`] === 0 &&
            typeof optionValue[`${menuType}_status`] === "number") {
                response = false;
          
        } 

       

        /*
        const product = getProduct(restaurant.products, "id", optionValue.restaurant_product_id);

        if(product) {
            response = isHandleProduct(
                qrcode,
                common,
                restaurant,
                product,
                menuType,
                selectedRegimes
            );
        } else {
            response = false;
        }
        */
    }

    return response;
};

export default isHandleOptionValue;
