import HttpBackend from 'i18next-http-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';

/*


il ya un problème que je n'arrive pas, ne peux pas résoudre avec nextjs et la locale
la premiere fois qu'on vient sur le site, si il n'y a pas la langue dans l'url,
cela va prendre en compte la locale par défaut que j'ai setté dans la config ('fr').
Sauf, quand on arrive sur la page d'accueil

Quand on arrive sur la page d'accueil (https://biodegustation.foound.in), si on a setté un cookie NEXT_LOCAL=it
alors nextjs va rediriger vers https://biodegustation.foound.in/it

nextjs ne regarde pas le cookie si on arrive sur https://biodegustation.foound.in/contact,
aussi il va utilise la locale "fr"

c'est un comportement volontaire de leeur part
https://nextjs.org/docs/advanced-features/i18n-routing#leveraging-the-next_locale-cookie



*/

/*
const options = {
  // order and from where user language should be detected
  order: [ 'querystring', 'cookie',  'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  //order: [ 'cookie', 'localStorage'],
  // keys or params to lookup language from
  //lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  //lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  cookieMinutes: 100,

  // cache user language on
  caches: ['localStorage', 'sessionStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional htmlTag with lang attribute, the default is:
  //htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' }


};
*/


export const ni18nConfig = {
  supportedLngs: ['ar', 'zh', 'de', 'fr', 'en', 'es', 'hu',  'it', 'ja', 'ms', 'nl', 'pt', 'ru'],
  ns: ['webapp', 'universal', 'bib'],
  backend: {
    loadPath: 'https://assets.foound.io/i18n/{{ns}}/{{lng}}.json?t='+Date.now(),
  },
  fallbackLng: false,
  //defaultLocale: 'fr',
  debug: false,
  //detection: options,
  //use: [LanguageDetector, HttpBackend],
  use: [HttpBackend],
}
