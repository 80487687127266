//import firebase from '~/src/lib/firebase/clientApp';

import { firebaseConfig } from "~/src/lib/common/config";
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import actionsRestaurant from "~/redux/restaurant/actions";
import actionsCommon from "~/redux/common/actions";
import log from "~/src/lib/log";
import config from "~/src/config";
import getProduct from "~/src/lib/v2/product/getProduct";
import getOptionValue from "~/src/lib/common/product/options/getOptionValue";
import { DateTime } from "luxon";

const date1 = DateTime.local();
async function _handle1(domain, lang) {


    console.log('HANDLE FIRESTORE');
    console.log(lang);

    const diff = date1.diffNow('seconds').toObject();
    console.log(diff);

    if(diff.seconds > -5) {
        console.log('DO NOT RUN FIRESTORE');
        return false;
    }


    const url = `${config.assetsUrl}/${config.jsonFolder}/${domain}-${lang}.json?t=${Date.now()}`;

    const result = await fetch(url);

    const payload = await result.json();
    if (window.store && typeof window.store.dispatch === 'function') {

        

       
        window.store.dispatch(actionsRestaurant.loadRestaurantSuccess(payload));

        const state = window.store.getState();

        if (state &&
            state.common &&
            state.common.cart &&
            state.common.cart.products &&
            state.common.cart.products.length > 0
        ) {
            console.log('checkCart');
            //console.log(payload);
            //console.log(state);
            console.log(state.common.cart.products);

            state.common.cart.products.map((p) => {

                const product = getProduct(payload.products, "id", p.id);

                if (product && (product.available === 0 || product.active === 0)) {
                    const title = `${product.name} n'est plus disponible. Il est retiré de votre panier`;
                    window.store.dispatch(actionsCommon.cartProductRemoveByProductId(p.id));
                    alert(title);
                }

                if (p.properties && p.properties.length > 0) {
                    p.properties.map((pp) => {
                        if (pp.optionValueId && pp.optionId) {
                            const optionValue = getOptionValue(payload, pp.optionId, pp.optionValueId);
                            //console.log(33);
                            //console.log(optionValue);
                            if (optionValue && optionValue.restaurant_product_id && optionValue.restaurant_product_id > 0) {
                                const optionProduct = getProduct(payload.products, "id", optionValue.restaurant_product_id);

                                if (optionProduct.name === 'tofu') {
                                    //console.log(optionProduct.name);
                                    //console.log(optionProduct);
                                }

                                if (optionProduct && (optionProduct.available === 0 || optionProduct.active === 0)) {
                                    const title = `${optionProduct.name} n'est plus disponible. Il est retiré de votre panier`;
                                    window.store.dispatch(actionsCommon.cartProductRemoveByProductId(p.id));
                                    alert(title);
                                }
                            }
                        }

                        if (pp.values) {
                            pp.values.map((ppp) => {
                                const optionValue = getOptionValue(payload, pp.optionId, ppp.optionValueId);
                                //console.log(44);
                                //console.log(optionValue);
                                if (optionValue && optionValue.restaurant_product_id && optionValue.restaurant_product_id > 0) {
                                    const optionProduct = getProduct(payload.products, "id", optionValue.restaurant_product_id);



                                    if (optionProduct && (optionProduct.available === 0 || optionProduct.active === 0)) {
                                        const title = `${optionProduct.name} n'est plus disponible. Il est retiré de votre panier`;
                                        window.store.dispatch(actionsCommon.cartProductRemoveByProductId(p.id));
                                        alert(title);
                                    }
                                }
                            })

                        }

                    })
                }

            })


        }
    }
}

async function _handle(domain, db, lang) {

    //console.log(111111122);
    //console.log(domain);
    //console.log(db);

    const unsub = onSnapshot(doc(db, "jsonfile", domain), (doc) => {
        console.log("Current data: ", doc.data());

        const data = doc.data();
        log("Current data: ", data);
        console.log(data);

        _handle1(domain, lang);

    });

}



function initFirestore(domain, lang) {

    const app = initializeApp(firebaseConfig);

    //log('INIT FIRESTORE');
    //log(domain);

    console.log('INIT FIRESTORE');
    console.log(domain);

    if (domain) {
        if (typeof window !== 'undefined') {
            try {
                const db = getFirestore(app);
                _handle(domain, db, lang);
            } catch (err) {
                console.error(err);
            }
        } else {
            console.error('WE ARE IN SERVER SIDE');
        }
    }
}

export { initFirestore };