import request from './request'

const get = (url, isAuth = false, accessToken=false) => {

    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
    };

    if (isAuth) {

        let token = '';
        if(accessToken) {
            token = accessToken;
        } else {
            const state = store.getState();
            const {user} = state;
            token = user?.token;
        }

        headers.Authorization = 'Bearer ' + token;
    }

    return request({
        url: url,
        method: 'GET',
        headers: headers
    });
}


const create = (url, options, isAuth = false) => {

    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
    };

    if (isAuth) {
        const state = store.getState();
        const {user} = state;
        const token = user?.token;
        headers.Authorization = 'Bearer ' + token;
    }

    return request({
        url: url,
        method: 'POST',
        data: options,
        headers: headers
    });
}

const postFormData = async (url, options, isAuth = false) => {

    const headers = {
        "Content-Type": "multipart/form-data"
    };

    if (isAuth) {
        const state = store.getState();
        const {user} = state;
        const token = user?.token;
        headers.Authorization = 'Bearer ' + token;
    }

    return request({
        url: url,
        method: 'POST',
        data: options,
        headers: headers
    });
}

const update = async (url, options, isAuth = false) => {

    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
    };

    if (isAuth) {
        const state = store.getState();
        const {user} = state;
        const token = user?.token;
        headers.Authorization = 'Bearer ' + token;
    }

    return request({
        url: url,
        method: 'PUT',
        data: options,
        headers: headers
    });
}

const destroy = async (url) => {

    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
    };

    const state = store.getState();
    const {user} = state;
    const token = user?.token;
    headers.Authorization = 'Bearer ' + token;

    return request({
        url: url,
        method: 'DELETE',
        headers: headers
    });
}


const MessageService = {
    create, 
    destroy,
    get, 
    postFormData, 
    update
}

export default MessageService;