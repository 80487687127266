import * as log from 'loglevel';

export const app = (msg, type = 'info') => {


    log.warn(msg);


};
  
export default app;

