import update from "immutability-helper";
import { SET_COOKIE_CONSENT, RESET_COOKIE_CONSENT } from "./constants";

export const initialState = [];

const cookie = (state = initialState, action) => {
  switch (action.type) {
    case SET_COOKIE_CONSENT: {

        const index = state.findIndex(
            item => item.name === action.payload.name
        );

        //console.log('SET_COOKIE_CONSENT');
        //console.log(state);
        //console.log(typeof state);
        //console.log(index);
        //console.log(action.payload);

        
        if (index !== -1) {
            return update(state, {
                [index]: { $merge: { accept: action.payload.accept } } 
            });
        } else {
            return update(state, {
                 $push: [action.payload]
            });      
        }
    }

    case RESET_COOKIE_CONSENT: {
        return initialState;
    }
    

    default:
      return state;
  }
};

export default cookie;