import moment from "moment/moment";
import "moment/locale/ar";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/ja";
import "moment/locale/nl";
import "moment/locale/pt";
import "moment/locale/ru";


import ar from 'date-fns/locale/ar';
import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import ja from 'date-fns/locale/ja';
import nl from 'date-fns/locale/nl';
import pt from 'date-fns/locale/pt';
import ru from 'date-fns/locale/ru';
import hu from 'date-fns/locale/hu';
import ms from 'date-fns/locale/ms';

registerLocale('ar', ar);
registerLocale('de', de);
registerLocale('es', es);
registerLocale('fr', fr);
registerLocale('it', it);
registerLocale('ja', ja);
registerLocale('nl', nl);
registerLocale('pt', pt);
registerLocale('ru', ru);
registerLocale('hu', hu);
registerLocale('ms', ms);

import { setDefaultLocale, registerLocale } from "react-datepicker";


export const setLocale = (lang) => {


  if (lang === "ar") {
    moment.locale("en");
  } else {
    moment.locale(lang);
  }


  moment.updateLocale(lang, {
    week: {
      dow: 1,
    },
  });


  console.log(665544);
  console.log(lang);






};

export default setLocale;

/*
async function setLocale(lang) {
  let result = await import("moment/locale/fr");
  moment.locale(lang);
}
*/
