import update from "immutability-helper";
import { SET_CURRENT_CATEGORY, 
    SET_CURRENT_SUBCATEGORY,
    SET_IS_CATEGORY_TAB_VISIBLE
} from "./constants";

export const initialState = {
    currentCategory: false,
    currentSubCategory: false,
    isCategoryTabVisible: false,
};

const menu = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_CATEGORY: {
        return {
            ...state,
            currentCategory: action.payload
        };
    }

    case SET_CURRENT_SUBCATEGORY: {
        return {
            ...state,
            currentSubCategory: action.payload
        };
    }

    case SET_IS_CATEGORY_TAB_VISIBLE: {
        return {
            ...state,
            isCategoryTabVisible: action.payload
        };
    }

    default:
      return state;
  }
};

export default menu;