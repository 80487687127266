import {
    SET_LANG,
} from "./constants";

const actions = {

    setLang: payload => ({
        type: SET_LANG,
        payload
    }),



};
export default actions;
