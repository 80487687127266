export const APP_READY = "APP_READY";
export const INIT = "INIT";
export const IS_CLIENT_SELECT_DATE = "IS_CLIENT_SELECT_DATE";
export const SET_PROCESSING_PRODUCTS_INFO = "SET_PROCESSING_PRODUCTS_INFO";
export const SET_VISIT_FROM = "SET_VISIT_FROM";
export const SET_TABLE_INFO = "SET_TABLE_INFO";
export const SET_QRCODE_INFO = "SET_QRCODE_INFO";
export const VISIT_FROM_TABLE = "TABLE";
export const VISIT_FROM_QRCODE = "QRCODE";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const TESTCD = "TESTCD";
export const SET_DOMAIN = "SET_DOMAIN";
export const SET_HEADERS = "SET_HEADERS";
export const SET_CTX = "SET_CTX";
export const SET_URL = "SET_URL";
export const SET_SWIPE = "SET_SWIPE";
export const SET_SWIPE_PRODUCT = "SET_SWIPE_PRODUCT";
export const SET_SELECTED_MENU = "SET_SELECTED_MENU";
export const SET_HEADER_HEIGHT = "SET_HEADER_HEIGHT";
export const SET_COOKIE_MODAL = "SET_COOKIE_MODAL";
export const SET_ERROR_DOMAIN = "SET_ERROR_DOMAIN";
export const SET_IP = "SET_IP";
export const SET_TIP = "SET_TIP";
export const RESET_TIP = "RESET_TIP";
export const SHOW_COOKIE_QRCODE_EXPIRE_ALERT = "SHOW_COOKIE_QRCODE_EXPIRE_ALERT";

export const MINI_VIEWER = "MINI_VIEWER";
