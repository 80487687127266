import getProduct from "~/src/lib/v2/product/getProduct";
import { OPTION_VALUE_TYPE_FREE_TEXT } from "~/src/config/constants";

export const isOptionValueActive = (restaurant, optionValue) => {

    let response = true;

    const dejaTraite = [];
    //on verifie qu'il n'y ait pas une boucle infinie

    const getProductActive = productId => {
        const product = getProduct(restaurant.products, "id", productId);
        if (product) {
            if (product.active === 0) {
                response = false;
            }

            if (product.ingredientsAdded && product.ingredientsAdded.length > 0) {
                product.ingredientsAdded.forEach(i => {
                    if (dejaTraite.includes(i) === false) {
                        dejaTraite.push(i);
                        getProductActive(i);
                    }
                });
            }

            if (
                product.ingredientsIncluded &&
                product.ingredientsIncluded.length > 0
            ) {
                product.ingredientsIncluded.forEach(i => {
                    if (dejaTraite.includes(i) === false) {
                        dejaTraite.push(i);
                        getProductActive(i);
                    }
                });
            }
        }
    };

    if (optionValue.type === OPTION_VALUE_TYPE_FREE_TEXT) {
        return true;
    }

    if (
        optionValue.restaurant_product_id &&
        optionValue.restaurant_product_id > 0
    ) {
        getProductActive(optionValue.restaurant_product_id);
        dejaTraite.push(optionValue.restaurant_product_id);
    }

    return response;


};

export default isOptionValueActive;
