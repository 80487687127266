import { LOAD_RESTAURANT, LOAD_RESTAURANT_SUCCESS, UPDATE_RESTAURANT, SET_RESTAURANT, UPDATE_PRODUCT } from "./constants";

const actions = {
    loadRestaurant: (payload) => ({
        type: LOAD_RESTAURANT,
        payload
    }),
    loadRestaurantSuccess: payload => ({
        type: LOAD_RESTAURANT_SUCCESS,
        payload
    }),
    updateRestaurant: (payload) => ({
        type: UPDATE_RESTAURANT,
        payload
    }),
    setRestaurant: (payload) => ({
        type: SET_RESTAURANT,
        payload
    }),
    updateProduct: (payload) => ({
        type: UPDATE_PRODUCT,
        payload
    }),


};
export default actions;
