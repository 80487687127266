import {
    APP_READY,
    INIT,
    IS_CLIENT_SELECT_DATE,
    SET_PROCESSING_PRODUCTS_INFO,
    SET_VISIT_FROM,
    SET_TABLE_INFO,
    SET_QRCODE_INFO,
    SET_ACTIVE_TAB,
    TESTCD,
    SET_DOMAIN,
    SET_HEADERS,
    SET_CTX,
    SET_URL,
    SET_SWIPE,
    SET_SWIPE_PRODUCT,
    SET_SELECTED_MENU,
    SET_HEADER_HEIGHT,
    SET_COOKIE_MODAL,
    SET_ERROR_DOMAIN,
    SET_IP,
    RESET_TIP,
    SET_TIP,
    SHOW_COOKIE_QRCODE_EXPIRE_ALERT,
    MINI_VIEWER
} from "./constants";


const actions = {

    loginSuccess: () => ({
        type: 'LOGIN_SUCCESS'
    }),

    logout: () => ({
        type: 'LOGOUT'
    }),

    init: (payload) => ({
        type: INIT,
        payload
    }),
    appReady: () => ({
        type: APP_READY
    }),
    setDomain: payload => ({
        type: SET_DOMAIN,
        payload
    }),

    setIp: payload => ({
        type: SET_IP,
        payload
    }),

    setHeaders: payload => ({
        type: SET_HEADERS,
        payload
    }),

    setCtx: payload => ({
        type: SET_CTX,
        payload
    }),

    setHeaderHeight: payload => ({
        type: SET_HEADER_HEIGHT,
        payload
    }),

    setCookieModal: payload => ({
        type: SET_COOKIE_MODAL,
        payload
    }),


    setErrorDomain: payload => ({
        type: SET_ERROR_DOMAIN,
        payload
    }),



    setUrl: payload => ({
        type: SET_URL,
        payload
    }),

    setSwipe: payload => ({
        type: SET_SWIPE,
        payload
    }),

    setSwipeProduct: payload => ({
        type: SET_SWIPE_PRODUCT,
        payload
    }),



    setActiveTab: payload => ({
        type: SET_ACTIVE_TAB,
        payload
    }),

    setClientSelectDate: payload => ({
        type: IS_CLIENT_SELECT_DATE,
        payload
    }),
    setProcessingProductsInfo: payload => ({
        type: SET_PROCESSING_PRODUCTS_INFO,
        payload
    }),
    setVisitFrom: payload => ({
        type: SET_VISIT_FROM,
        payload
    }),
    setTableInfo: payload => ({
        type: SET_TABLE_INFO,
        payload
    }),
    setQrcodeInfo: payload => ({
        type: SET_QRCODE_INFO,
        payload
    }),
    testcd: payload => ({
        type: TESTCD,
        payload
    }),
    setSelectedMenu: payload => ({
        type: SET_SELECTED_MENU,
        payload
    }),

    resetTip: () => ({
        type: RESET_TIP
    }),

    setTip: payload => ({
        type: SET_TIP,
        payload
    }),
    
    showCookieQrcodeExpireAlert: payload => ({
        type: SHOW_COOKIE_QRCODE_EXPIRE_ALERT,
        payload
    }),

    setMiniViewer: payload => ({
        type: MINI_VIEWER,
        payload
    }), 

};
export default actions;
