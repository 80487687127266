import axios     from 'axios';
import config from "~/src/config";
//import * as Sentry from '@sentry/react';

import toast from "~/src/lib/toast";
import isMobile from "~/src/lib/isMobile";




const _displayError = (msg, trad=false) => {

    if(trad === true) {
        if(store && typeof store === 'object' && typeof store.getState === 'function') {
            const state = store.getState();

            if(typeof state === 'object') {
                const {restaurant} = state;

                if(typeof restaurant === 'object' && typeof restaurant.translate === 'object') {
                    toast(msg, 'error');
                }
            }
        }
    } else {
        toast(msg, 'error');
    }

}


const request = async (options) => {

    /**
     * Create an Axios Client with defaults
     */

    let opt = {};
    if(options.url.search("http") === -1) {
        opt.baseURL = config.apiUrl;
    }

    const client = axios.create(opt);


    try {
        const response = await client(options);
        // Success 🎉
        //console.log(response);

        return {
            result: true,
            data: response.data.data ? response.data.data : response.data
        }
    } catch (error) {


        // Error 😨
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */


            console.log(`request error 1 : ${JSON.stringify(error)}`);
            _displayError('error status code');
            //Sentry.captureException(`error response :: ${JSON.stringify(error)} :: ${JSON.stringify(options)}`);
            return {
                result: false,
                data: error.response.data
            }

            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            //console.log(error.request);

            console.log(`request error 2 : ${JSON.stringify(error)}`);
            if(isMobile() === true) {
                _displayError(`Le serveur n'est pas accessible.
                Si vous êtes en wifi, essayez de le désactiver et de recommencer, votre entreprise bloque peut-être notre serveur.`);
            } else {
                _displayError('k308', true);
            }

            //Sentry.captureException(`error request :: ${JSON.stringify(error)} :: ${JSON.stringify(options)}`);
            return {
                result: false,
                message: 'no response was received'
            }

        } else {

            console.log(`request error  3: ${JSON.stringify(error)}`);
            _displayError('unknown error');
            //Sentry.captureException(`unknown error :: ${JSON.stringify(error)} :: ${JSON.stringify(options)}`);
            return {
                result: false,
                message: 'unknown error'
            }
            // Something happened in setting up the request and triggered an Error
            //console.log('Error', error.message);
        }
        //console.log(error);
    }




}

export default request;