import getRegime from "./getRegime";

export const getRegimeCompliantObjectById = (
  restaurant,
  regimeId,
  compliantId
) => {
  const regime = getRegime(restaurant.regimes, regimeId);

  if (regime) {
    const compliantPossibleRegime = regime.values.find(
      item => item.compliant === compliantId
    );
    if (compliantPossibleRegime) {
      return compliantPossibleRegime;
    } else {
      return false;
    }
  }
  return false;
};

export default getRegimeCompliantObjectById;
