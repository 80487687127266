import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import App from 'next/app';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import configureStore from '../redux/store';
import theme from "~/src/theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ToastContainer } from "react-toastify";
import Head from "next/head";
import getDomain from '~/src/lib/getDomain';
import actionsRestaurant from "~/redux/restaurant/actions";
import { DateTime } from "luxon";
import messageService from "~/src/lib/req/message";
import config from "~/src/config";
import isMobile from "~/src/lib/isMobile";
import { useRouter } from 'next/router';
import * as storage from "~/src/lib/common/storage/browserStorage";
import * as analytics from "~/src/lib/analytics";
//import useSWR from 'swr';
import getCustomButton1 from "~/src/lib/getCustomButton1";

import dynamic from "next/dynamic";
//import request from 'superagent';

import Cookies from 'cookies'

import { appWithI18Next } from 'ni18n';
import { ni18nConfig } from '../ni18n.config';

import './css/styles.css';
import './css/mediaquery.css';
import './css/animation.css';

import "react-placeholder/lib/reactPlaceholder.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "~/src/styles/file-picker.css";
import 'react-toastify/dist/ReactToastify.css';

import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";
import "~/public/css/slick-theme.css";

import '~/src/styles/stripe.css';

import useWindowOrientation from "use-window-orientation";

import postNativeApp from '~/src/lib/postNativeApp';
import checkCookieQrcode from '~/src/lib/checkCookieQrcode';

import cookie from 'cookie';

import { useTranslation } from 'react-i18next';
import useInterval from '~/src/hooks/useInterval';


const Layout = dynamic(() => import('~/src/components/Layout'), {
    ssr: false
});

const HandleLocaleRedirect = dynamic(() => import('~/src/components/HandleLocaleRedirect'), {
    ssr: false
});





/*
const d = DateTime.fromSQL("2021-05-03 12:16:00");
const d1 = DateTime.fromSQL("2021-05-03 12:16:00", { zone: 'Europe/Paris'});
const d2 = DateTime.fromSQL("2021-05-03 12:16:00", { zone: 'America/Los_Angeles'});

const d3 = DateTime.local();
const d4 = DateTime.local().setZone('America/Los_Angeles');

console.log('HHHHHHH');
console.log(d.toLocaleString(DateTime.TIME_SIMPLE));
console.log(d1.toLocaleString(DateTime.TIME_SIMPLE));
console.log(d2.toLocaleString(DateTime.TIME_SIMPLE));
console.log(d2.toFormat('HH'));
console.log(d2.setZone('America/Los_Angeles').toFormat('HH'));

console.log(d3.toFormat('HH'));
console.log(d4.toFormat('HH'));
*/
//let isDispatch = false;


setInterval(() => {
    checkCookieQrcode();
}, 10000);


/**
 * Object.entriesFrom() polyfill
 * @author Chris Ferdinandi
 * @license MIT
 */
 if (!Object.fromEntries) {
	Object.fromEntries = function (entries){
		if (!entries || !entries[Symbol.iterator]) { throw new Error('Object.fromEntries() requires a single iterable argument'); }
		let obj = {};
		for (let [key, value] of entries) {
			obj[key] = value;
		}
		return obj;
	};
}


function MyApp({ Component, pageProps, store }) {

    //console.log(2222288);
    //const state = store.getState();
    //const {user} = state;
    //console.log(user);
    const router = useRouter();
    const { orientation, portrait, landscape } = useWindowOrientation();
    const { i18n } = useTranslation();


    //console.log('CYRIIIIIIIILLE');
    //console.log(router);
    //console.log(i18n);
 


    /*
    useInterval(
        async () => {
          const { buildId } = (await request.get('/api/build-id')).body;
    
          console.log(111);
          console.log(buildId);
          console.log(process.env.BUILD_ID);
    
          if (buildId && process.env.BUILD_ID && buildId !== process.env.BUILD_ID) {
            //alert('new version of website is available, please reload the page');
          }
        },
        { interval: 15000 },
    );
    */



    setTimeout(() => {
        //console.log(22222);
        postNativeApp();
    }, 2000);




    useEffect(() => {

        const handleRouteChange = (url, { shallow }) => {

            analytics.logPageView(url);
           
            //storage.setItem("referrer", document.location.pathname);
            storage.setItem("referrer", router.asPath);
            //console.log(`App is changing to ${url}`);
            //console.log(document.location);
            //console.log(router);
        }

        router.events.on('routeChangeStart', handleRouteChange)

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off('routeChangeStart', handleRouteChange)
        }
    }, [router])

    if (store && typeof document === 'object') {
        const state = store.getState();
        if (state) {

            const { restaurant } = state;

            if (restaurant && restaurant.info && restaurant.info.custom_css) {
                //console.log(restaurant.info.custom_css);

                const customCss = document.createElement('style');
                customCss.innerText = restaurant.info.custom_css;
                document.getElementsByTagName("head")[0].appendChild(customCss);

            }

            if (restaurant && restaurant.info && restaurant.info.custom_css_url) {
                const head = document.head;
                const link = document.createElement("link");

                link.type = "text/css";
                link.rel = "stylesheet";
                link.href = restaurant.info.custom_css_url;

                head.appendChild(link);
            }


            if (restaurant && restaurant.info && restaurant.info.favicon) {
                const head = document.head;
                const link = document.createElement("link");

                link.type = "image/png";
                link.rel = "icon";
                link.href = `${config.assetsUrl}/favicon/${restaurant.info.favicon}` ;

                head.appendChild(link);
            }


        }
    }

    //console.log(2222222);
    //console.log(orientation);
    //console.log(portrait);
    //console.log(landscape);

    /*
    if(isMobile() === true && landscape) {
        return (
            <div style={{
                //margin: 'auto',
                backgroundColor: '#FFFFFF',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'

  
            }}>
                <div style={{
                                  
                    textAlign: 'center',
                    fontSize: 40,
                    color: '#C1C1C1'
                }}>
                Please rotate
                </div>
            </div>
        )
    }
    */



    return (
        <Provider store={store}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <HandleLocaleRedirect />
                <Layout>
                    <Component {...pageProps} />
                </Layout>
                <ToastContainer />
            </MuiThemeProvider>
        </Provider>
    );
}

//console.log('111111');

MyApp.getInitialProps = async (appContext) => {

    //console.log('zeufleuflzefzelfbezbfzebfzebfzefez');

    const appProps = await App.getInitialProps(appContext);
    appProps.revalidate = 60;

    // cyrille test json locale
    //var jsonData = require('~/src/json/cyrille-fr.json');

    //const fetcher = (url) => fetch(url).then((res) => res.json());
    //const { data, error } = useSWR('${config.jsonUrl}/breguet-fr.json', fetcher);

    let lang = appContext.ctx.locale;

    //console.log('????????????????');
    //console.log(appContext.ctx.locale);
    //console.log(appContext.router.locale);




    if (appContext.ctx && appContext.ctx.isServer === true) {

        console.log('STEP1');
        console.log(lang);
        console.log(appContext.ctx.req.url);
        console.log(appContext.ctx.req.headers.host);
        //console.log(appContext.router);

        if(process.env.NEXT_PUBLIC_CLIENT) {
            console.log(`NEXT_PUBLIC_CLIENT => ${process.env.NEXT_PUBLIC_CLIENT}`)
        }

        if( ['/error_restaurant', '/qrcode'].includes(appContext.ctx.req.url) === false) {
            let redirect = false;
            const domain = getDomain(appContext.ctx.req.headers.host) ;
            console.log(domain);
            let url = `${config.jsonUrl}/${domain}-${lang}.json?time=${Date.now()}`;
            console.log(url);
            const res = await fetch(url);
            //console.log(111);
            //console.log(res.status);
            
            let data = false;

            console.log('STEP2');

            if(res.status === 200) {
                data = await res.json();
            } else {
                lang = 'fr';
                url = `${config.jsonUrl}/${domain}-${lang}.json`;
                const res = await fetch(url);
                console.log(url);
                //console.log(res.status);
                if(res.status === 200) {
                    data = await res.json();
                } else {
                    lang = 'en';
                    url = `${config.jsonUrl}/${domain}-${lang}.json`;
                    const res = await fetch(url);
                    //console.log(333);
                    //console.log(res.status);
                    if(res.status === 200) {
                        data = await res.json();
                    } 
                }
            }

            console.log('STEP3');
            //console.log(data);
            //console.log(appContext.ctx);

            if(appContext.ctx.locale !== lang) {

                console.log('REDIRECT 1111');
                console.log(appContext.ctx.locale);
                console.log(lang);
                console.log(appContext.ctx.req.url);
                redirect = true;
                appContext.ctx.res.writeHead(302, {
                    Location: `/${lang}`
                }).end();

            
            }

            console.log('STEP4');

            if(!data && redirect === false) {
                console.log('REDIRECT 2222');
                redirect = true;
                appContext.ctx.res.writeHead(302, {
                    Location: '/error_restaurant'
                }).end();
            }

            console.log('STEP5');

            //console.log(appContext.ctx.res);
            //appContext.ctx.res.cookie('cookieName',lang, { maxAge: 900000, httpOnly: true });

            if(data && redirect === false) {
                const cookies = new Cookies(appContext.ctx.req, appContext.ctx.res);
                cookies.set('NEXT_LOCALE', lang, {
                    httpOnly: false, // true by default
                    maxAge: 30 * 24 * 600 * 600,
                    path: '/'
                })
                cookies.set('lang', lang, {
                    httpOnly: false, // true by default
                    maxAge: 30 * 24 * 600 * 600,
                    path: '/'
                })     
    
                appContext.ctx.store.dispatch(actionsRestaurant.loadRestaurant({ req: appContext.ctx.req, data: data, lang: lang }));
            }

            console.log('STEP6');
            

            /*
            const cookies = parseCookies(appContext.ctx.req);
            console.log(cookies);
            if(cookies.NEXT_LOCALE) {
                console.log(cookies.NEXT_LOCALE); 
            }
            */

        }
    }
 






    if (appContext.ctx && appContext.ctx.isServer === true) {

        //console.log(1111111);
        //console.log(appContext.router.query);
        //console.log(appContext.ctx.isServer);
        //console.log(appContext.ctx);

        appProps.pageProps.host = appContext.ctx.req.headers.host;
        //appProps.pageProps.res = appContext.ctx.res;




        if (appContext.router && appContext.router.pathname && appContext.router.pathname === '/order_takeaway') {

            if (appContext.router.query && appContext.router.query.session_id) {
                // page de confirmation stripe
            } else {
                let protocol = 'https';
                if (appContext.ctx.req.headers.host.includes("localhost")) {
                    protocol = 'http';
                }
                if (appContext.ctx.res) {
                    appContext.ctx.res.writeHead(301, {
                        Location: `${protocol}://${appContext.ctx.req.headers.host}`
                    });
                    appContext.ctx.res.end();
                }
            }
        }



        //console.log(1111111);
        //console.log(appContext.router.pathname);


        //console.log(appContext.ctx.req.headers.host);

        if (appContext.router && appContext.router.pathname && appContext.router.pathname === '/sitemap.xml') {

            //console.log(22222);
            const host = appContext.ctx.req.headers.host;

            const url = `/sitemap/${host}`;
            //console.log(url);
            const result = await messageService.get(`/sitemap/${host}`);

            //console.log(22233);
            //console.log(result);

            if (result && result.result === true && result.data) {
                appContext.ctx.res.statusCode = 200;
                appContext.ctx.res.setHeader('Content-Type', 'application/xml');
                //appContext.ctx.res.status(200).send(xml);
                appContext.ctx.res.write(result.data);
                appContext.ctx.res.end();
            }
        }

    }

  

    return { ...appProps }
}


export default configureStore(MyApp);

//export default appWithI18Next(configureStore(MyApp), ni18nConfig);
