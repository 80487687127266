//import _ from "underscore";
import findWhere from "../utils/findWhere";
import getCompositionProperty from "../product/composition/getCompositionProperty";
import getRegimeCompliantObjectById from "./getRegimeCompliantObjectById";
import getOption from "../product/options/getOption";
import getRegime from "./getRegime";
import isOptionMandatory from "~/src/lib/v2/option/isOptionMandatory";
import getOptionWithFilteredValues from "~/src/lib/v2/option/getOptionWithFilteredValues";

import {union, sortBy} from 'underscore';

//import {getProduct} from '../product/product';

/*
0 => ask
1 => no
2 => yes 
3 => possible 
4 => removable
5 => unknown
*/

function convertIconNameToNumber(name) {
  if (name === "no") {
    return 0;
  }
  if (name === "removable") {
    // igor
    return 2;
  }

  if (name === "ask") {
    return 1;
  }
  if (name === "possible") {
    return 2;
  }

  if (name === "yes") {
    return 3;
  }
  return 4;
}

function _getIconName(data) {
  const o = {};
  data.map(d => {

    o[d.compliantId] = 1;
  });

  if (typeof o[4] === "number" && typeof o[1] === "undefined") {
    // igor
    return "removable";
  }

  if (typeof o[3] === "number" && typeof o[1] === "undefined") {
    return "possible";
  }
  if (typeof o[0] === "number" && typeof o[1] === "undefined") {
    return "ask";
  }
  if (typeof o[5] === "number" && typeof o[1] === "undefined") {
    return "unknown"; // anciennement unknown
  }

  return "no";
}

function _isProcessProduct(product) {
  if (product.active === 1) {
    return true;
  }
  return false;
}

function _isProcessItem(item, menuType) {
  if (item[`${menuType}_status`] && item[`${menuType}_status`] === 1) {
    return true;
  }
  return false;
}

function _calcul(
  restaurant,
  selectedRegimes,
  composition,
  product,
  data,
  menuType
) {


  if (data.length === 0) {
    let v = "yes";
    selectedRegimes.map(regimeId => {
      if (
        _verifyIsOptionPossible(
          restaurant,
          regimeId,
          composition,
          product,
          menuType,
          true
        )
      ) {
        v = "possible";
      }
      //v = 'possible';
    });
    return v;
  } else {
    return _getIconName(data);
  }
}


function _calcul2(data) {
  
    if (data.length === 0) {
      return "yes";
    } else {
      return _getIconName(data);
    }
  }

function _build(productId, regimeId, name, compliantId, logo, description) {
  return {
    compliantId: compliantId,
    name: name,
    description: description,
    productId: productId,
    regimeId: regimeId,
    logo: logo
  };
}

function _getPriority(compliantId) {
  let priority = 1;
  if (compliantId === 2) {
    //oui
    priority = 1;
  }
  if (compliantId === 3) {
    //possible
    priority = 2;
  }
  if (compliantId === 0) {
    priority = 3;
  }
  if (compliantId === 1) {
    priority = 4;
  }
  return priority;
}

function _isVerifyIngredient(id, composition) {
  if (!composition) {
    return true;
  }
  if (
    composition.removalIngredients &&
    composition.removalIngredients.includes(id)
  ) {
    return false;
  }

  return true;
}

function _verifyIsOptionPossible(
  restaurant,
  regimeId,
  composition,
  product,
  menuType,
  specific=false
) {
  // on recupere la liste des options non encore selectionnées
  let response = false;
  product.options.forEach(optionId => {
    const match = getCompositionProperty(
      composition,
      "options",
      "optionId",
      optionId
    );
    if (!match) {
      const option = getOption(restaurant, optionId);

      //on regarde que l'option est obligatoire
      if (option && option.minimum > 0) {
   
        const res = {};
        option.values.forEach(optionValue => {
          res[
            getCompliantNameForAOption(
              option.id,
              optionValue.id,
              [regimeId],
              restaurant,
              composition,
              menuType,
              false,
              specific
            )
          ] = 1;
       
        });
     
        if (typeof res["yes"] === "number" && typeof res["no"] === "number") {
          response = true;
        }
      }
    }
  });

  return response;
}

// uniquement les ingredients du plat principal
function _getCompliantPlatIngredientsAdded(
  product,
  restaurant,
  regimeId,
  composition
) {
  let data = [];

  if (product) {
    if (_isVerifyIngredient(product.id, composition)) {
      if (product.ingredientsAdded) {
        product.ingredientsAdded.forEach(ingredientId => {
          const product1 = findWhere(restaurant.products, "id", ingredientId);
          if (product1) {
            if (product1 && _isVerifyIngredient(product1.id, composition)) {
              //??? cyrille
              //if (product.id === 64) {

              if (product1 && product1.regimes) {
                const result = _getCompliantRegimes(
                  restaurant,
                  product1,
                  regimeId,
                  composition
                );

                result.map(i => {
                  if (
                    i.compliantId === 1 &&
                    product1.removable === 1 &&
                    composition &&
                    composition.removalIngredients &&
                    composition.removalIngredients.includes(i.productId)
                  ) {
                    i.compliantId = 3;
                  } else if (
                    i.compliantId === 1 &&
                    product1.removable === 1 &&
                    composition &&
                    composition.removalIngredients &&
                    !composition.removalIngredients.includes(i.productId)
                  ) {
                    i.compliantId = 3;
                    if (
                      getRegimeCompliantObjectById(
                        restaurant,
                        i.regimeId,
                        4
                      ) !== false
                    ) {
                      i.name = getRegimeCompliantObjectById(
                        restaurant,
                        i.regimeId,
                        4
                      ).name;
                      i.logo = getRegimeCompliantObjectById(
                        restaurant,
                        i.regimeId,
                        4
                      ).logo;
                    }
                  }
                });
                data = union(data, result);
              }
            }
          }
        });
      }
    }
  }

  return data;
}

function _getCompliantPlatIngredientsIncluded(
  product,
  restaurant,
  regimeId,
  composition
) {
  let data = [];

  if (product) {
    if (_isVerifyIngredient(product.id, composition)) {
      if (product.ingredientsIncluded) {
        product.ingredientsIncluded.forEach(ingredientId => {
          const product1 = findWhere(restaurant.products, "id", ingredientId);
          if (product1 && _isVerifyIngredient(product1.id, composition)) {
            if (product1 && product1.regimes) {
              const result = _getCompliantRegimes(
                restaurant,
                product1,
                regimeId,
                composition
              );
              result.map(i => {
                if (
                  i.compliantId === 1 &&
                  product1.removable === 1 &&
                  composition &&
                  composition.removalIngredients &&
                  !composition.removalIngredients.includes(i.productId)
                ) {
                  i.compliantId = 3;
                  if (
                    getRegimeCompliantObjectById(restaurant, i.regimeId, 4) !==
                    false
                  ) {
                    i.name = getRegimeCompliantObjectById(
                      restaurant,
                      i.regimeId,
                      4
                    ).name;
                    i.logo = getRegimeCompliantObjectById(
                      restaurant,
                      i.regimeId,
                      4
                    ).logo;
                  }
                }
              });
              data = union(data, result);
            }
          }
        });
      }
    }
  }

  return data;
}

function _getCompliantRegimes(restaurant, product, regimeId, composition) {
  let data = [];
  if (_isVerifyIngredient(product.id, composition)) {
    //cyrille
    //if (1 === 1) {
    product.regimes.forEach(productRegime => {
      const regime = getRegime(restaurant.regimes, productRegime.regime_id);
      if (regime && regime.values) {
        const regimeValue = regime.values.find(
          item => item.id === productRegime.regime_value_id
        );

        if (regimeId === productRegime.regime_id) {
          if ([1, 3, 5].includes(productRegime.compliant)) {
    
            data.push(
              _build(
                product.id,
                regime.regime_id,
                regimeValue.name,
                productRegime.compliant,
                regimeValue.logo,
                regime.description
              )
            );
          }
        }
      }
    });
  }

  return data;
}

function _getCompliantProductSimple(
  product,
  restaurant,
  regimeId,
  composition
) {
  let data = [];

  /*  REGIMES DU PLAT */
  if (product && product.regimes) {
    const result = _getCompliantRegimes(
      restaurant,
      product,
      regimeId,
      composition
    );
    data = union(data, result);
  }

  /*  REGIMES DES INGREDIENTS */
  if (product && product.ingredientsAdded) {
    const result1 = _getCompliantPlatIngredientsAdded(
      product,
      restaurant,
      regimeId,
      composition
    );
    data = union(data, result1);
  }

  if (product && product.ingredientsIncluded) {
    const result1 = _getCompliantPlatIngredientsIncluded(
      product,
      restaurant,
      regimeId,
      composition
    );
    data = union(data, result1);
  }

  return data;
}

function _getCompliantForAProduct(params) {


  const composition = params.composition ? params.composition : {};
  const product = params.product ? params.product : {};
  const restaurant = params.restaurant ? params.restaurant : {};
  const regimeId = params.regimeId ? params.regimeId : false;
  const menuType = params.menuType ? params.menuType : 'inside';
  const isPrimaryProduct = params.isPrimaryProduct ? params.isPrimaryProduct : false;
  const origin = params.origin ? params.origin : false;
  const filterAVailable = params.filterAVailable ? params.filterAVailable : true;

  let info = [];


  if(product.uuid === '2b5947a0-bce4-11e9-a566-410152645ed0' && isPrimaryProduct === true) {
    console.log(44);
    console.log(composition);
    console.log(product);
    console.log(isPrimaryProduct);
  }

  if (product && ((product.available === 1 || isPrimaryProduct === true) || filterAVailable === false)) {
    const result = _getCompliantProductSimple(
      product,
      restaurant,
      regimeId,
      composition
    );
    info = union(info, result);



    // OPTIONS
    if (
      composition &&
      composition.properties &&
      composition.properties.length > 0
    ) {



      product.options.forEach(optionId => {
        const match = getCompositionProperty(
          composition,
          "options",
          "optionId",
          optionId
        );

        if (match) {
          const option = getOption(restaurant, optionId);



          if (option) {
            const optionValues = [];
            if (match.values) {
              match.values.map(value => {
                const optionValue = findWhere(
                  option.values,
                  "id",
                  value["optionValueId"]
                );
                if (optionValue) {
                  optionValues.push(optionValue);
                }
              });
            } else {
              const optionValue = findWhere(
                option.values,
                "id",
                match["optionValueId"]
              );
              if (optionValue) {
                optionValues.push(optionValue);
              }
            }

            optionValues.map(optionValue => {
              if (optionValue && optionValue.restaurant_product_id) {
                const product1 = findWhere(
                  restaurant.products,
                  "id",
                  optionValue.restaurant_product_id
                );
                if (product1 && product1.regimes && (product1.available === 1 || isPrimaryProduct === true)) {
                  const result = _getCompliantProductSimple(
                    product1,
                    restaurant,
                    regimeId,
                    composition
                  );

                  if (origin === "menu") {
                    const option = getOption(restaurant, optionId);
                    if (option) {
                      if (
                        typeof option.minimum === "number" &&
                        option.minimum === 0
                      ) {
                        if (typeof result === "object" && result.length > 0) {
                          result[0].compliantId = 3;
                        }
                      }
                    }
                  }

                  info = union(info, result);
                }
              }
              return false;
            });
          }
        }
      });
    }
  }

  return info;
}

// Dans l’onglet regime, on affiche les regimes non compliant ou possible
// Uniquement pour les regimes en force display ou les regimes selectionnés

const getProductRegimesCompliant = (params) => {


    const composition = params.composition ? params.composition : {};
    const product = params.product ? params.product : {};
    const restaurant = params.restaurant ? params.restaurant : {};
    const selectedRegimes = params.selectedRegimes ? params.selectedRegimes : [];
    const menuType = params.menuType ? params.menuType : 'inside';
    const isPrimaryProduct = params.isPrimaryProduct ? params.isPrimaryProduct : false;

  const regimeInfo = [];

  const selectedRegimesSepc = [...selectedRegimes];


  restaurant.regimes.map((rr) => {

    if(rr.behavior === 'ignore') {
      return false;
    }

    if(rr.behavior === 'hide') {
      return false;
    }



    

    if(rr.behavior === 'highlight' && selectedRegimesSepc.includes(rr.regime_id) === false) {
      selectedRegimesSepc.push(rr.regime_id);
    }

   
   

    
    if(rr.behavior === 'display' && selectedRegimesSepc.includes(rr.regime_id) === false) {

      const result = _getCompliantForAProduct(
        {
          composition: composition,
          product: product,
          restaurant: restaurant,
          regimeId: rr.regime_id,
          menuType: menuType,
          isPrimaryProduct: isPrimaryProduct
        }
      );
     
      result.map(item => {
        if ([1, 3, 5].includes(item.compliantId)) {
          if(selectedRegimesSepc.includes(rr.regime_id) === false) {
            selectedRegimesSepc.push(rr.regime_id);
          }
        }
      });
    }
    

  });

  //console.log(2);
  //console.log(selectedRegimesSepc);
  
  // on parcourt la liste des regimes du produit
  product.regimes &&
    product.regimes.map(productRegime => {

      const restaurantRegime = getRegime(restaurant.regimes, productRegime.regime_id);

      if (restaurantRegime && restaurantRegime.values && restaurantRegime.value !== -1) {
        const regimeValue = restaurantRegime.values.find(
          item => item.id === productRegime.regime_value_id
        );
        const match = selectedRegimesSepc.includes(productRegime.regime_id);

        // si le regime est highlight ou parmi les regimes selectionnés
        if (restaurantRegime.behavior === 'highlight' || match) {
          // on recupere la valeur compliant
          const result = _getCompliantForAProduct(
            {
              composition: composition,
              product: product,
              restaurant: restaurant,
              regimeId: restaurantRegime.regime_id,
              menuType: menuType,
              isPrimaryProduct: isPrimaryProduct
            }
          );

          const result2 = sortBy(result, "compliantId");
          const verif = [];

          let pass = false;

          result2.map(item => {
            if ([1, 3, 5].includes(item.compliantId)) {
              if (verif.includes(restaurantRegime.regime_id) === false) {
                const o = {
                  regime_id: restaurantRegime.regime_id,
                  name: item["name"],
                  compliant: item.compliantId,
                  logo: item.logo,
                  priority: _getPriority(item.compliantId),
                  regime_value_id: regimeValue.id,
                  isSelectedRegime: match ? 1 : 0
                };
                regimeInfo.push(o);
                if (item.compliantId === 1) {
                  verif.push(restaurantRegime.regime_id);
                }
                pass = true;
              }
            }
           
          });

          // si on ne trouve pas de non compliant ou possible et que le regime est choisi
          // on affiche le nom du regime compliant
          if (pass === false && match) {
            if (
              _verifyIsOptionPossible(
                restaurant,
                restaurantRegime.regime_id,
                composition,
                product,
                menuType
              )
            ) {
              let name = {};
              let logo = "";
              if (
                getRegimeCompliantObjectById(
                  restaurant,
                  restaurantRegime.regime_id,
                  3
                ) !== false
              ) {
                name = getRegimeCompliantObjectById(
                  restaurant,
                  restaurantRegime.regime_id,
                  3
                ).name;
                logo = getRegimeCompliantObjectById(
                  restaurant,
                  restaurantRegime.regime_id,
                  3
                ).logo;
              }

              const o = {
                regime_id: restaurantRegime.regime_id,
                name: name,
                compliant: 3,
                logo: logo,
                priority: _getPriority(3),
                regime_value_id: regimeValue.id,
                isSelectedRegime: match ? 1 : 0
              };
              regimeInfo.push(o);
            } else {
              const o = {
                regime_id: restaurantRegime.regime_id,
                name: regimeValue.name,
                compliant: 2,
                logo: regimeValue.logo,
                priority: _getPriority(2),
                regime_value_id: regimeValue.id,
                isSelectedRegime: match ? 1 : 0
              };
              regimeInfo.push(o);
            }
          }
        }
      }
    });

  const result = [];
  const map = new Map();
  for (const item of regimeInfo) {
    const key = `${item.regime_id}-${item.regime_value_id}`;

    if (!map.has(key)) {
      map.set(key, true); // set any value to Map
      result.push(item);
    }
  }

  const tt = sortBy(result, "priority").reverse();


  return result;
};

// si une valeur d'option est fait d'un ingredient
// on verifie les regimes et les ingredients de cet ingredient
function getCompliantNameForAOption(
  optionId,
  optionValueId,
  selectedRegimes,
  restaurant,
  composition,
  menuType,
  justReturnArray = false,
  specific=false
) {
  if (selectedRegimes.length === 0) {
    return false;
  }
  let data = [];

  const option = getOption(restaurant, optionId);

  if (option && _isProcessItem(option, menuType)) {
    //type ingredient
    const optionValue = option.values.find(item => item.id === optionValueId);

    if (optionValue && _isProcessItem(optionValue, menuType)) {
      const product = restaurant.products.find(
        item => item.id === optionValue.restaurant_product_id
      );

      if (product) {
        selectedRegimes.map(regimeId => {
          const result = _getCompliantForAProduct(
            {
              composition: composition,
              product: product,
              restaurant: restaurant,
              regimeId: regimeId,
              menuType: menuType,
              origin: false,
              filterAVailable: false,
            }
          );
          data = union(data, result);
          return false;
        });

        if (justReturnArray === true) {
          return data;
        }

        if(specific) {
            return _calcul2(data);
        }
        return _calcul(
          restaurant,
          selectedRegimes,
          composition,
          product,
          data,
          menuType
        );
      }
    }
  }
  return "yes";
}

function getCompliantNameForAProduct(
  product,
  composition,
  selectedRegimes,
  restaurant,
  menuType,
  origin = false,
  justReturnArray = false
) {
  // si le client n'a pas sélectionné de regimes, on n'affiche rien
  if (selectedRegimes.length === 0) {
    return false;
  }

  let data = [];

  if (product && _isProcessProduct(product)) {
    selectedRegimes.map(regimeId => {
      const result = _getCompliantForAProduct(
        {     
          composition: composition,
          product: product,
          restaurant: restaurant,
          regimeId: regimeId,
          menuType: menuType,
          origin: origin,
        }
      );

      data = union(data, result);
    });
    if (justReturnArray === true) {
      return data;
    }
    return _calcul(
      restaurant,
      selectedRegimes,
      composition,
      product,
      data,
      menuType
    );
  }

  return false;
}

function isProductHasOptionNoCompliant(
  restaurant,
  product,
  selectedRegimes,
  menuType,
  qrcode,
  common
) {
  if (selectedRegimes.length === 0) {
    return false;
  }
  let result = false;

  if (product && product.options) {
    product.options.map(optionId => {
      const option = getOption(restaurant, optionId);
      if (option) {
        if (isOptionMandatory(option) === true) {
          if (option.values && option.values.length > 1) {
            const optionFiltered = getOptionWithFilteredValues(
                restaurant,
                option,
                menuType,
                common,
                qrcode,
                selectedRegimes
            );

            let nbNoCompliant = 0;
            optionFiltered.values.map(optionValue => {
              const compliantName = getCompliantNameForAOption(
                option.id,
                optionValue.id,
                selectedRegimes,
                restaurant,
                false,
                menuType
              );
              if (compliantName === "no") {
                nbNoCompliant++;
              }

              return false;
            });
            if (nbNoCompliant === optionFiltered.values.length) {
              result = true;
            }
          }
        }
      }
      return false;
    });
  }

  return result;
}

export {
  getCompliantNameForAProduct,
  getCompliantNameForAOption,
  getProductRegimesCompliant,
  convertIconNameToNumber,
  isProductHasOptionNoCompliant
};
