let assetsUrl = "https://assets.foound.io";
let baseUrl = 'https://api.foound.com';
let jsonUrl = "https://assets.foound.io/staticV4";
let client = 'general';
let logoUrl = `${assetsUrl}/logo/foound.png`;
let icsFile = 'foound.ics';
let clientUrl = 'https://client.foound.com';
let imgUrl = "https://img.foound.io";


if (process.env.NEXT_PUBLIC_CLIENT && process.env.NEXT_PUBLIC_CLIENT === 'elior') {
  baseUrl = 'https://api.elior.app';
  logoUrl = `${assetsUrl}/logo/elior.png`;
  icsFile = 'elior.ics';
  clientUrl = 'https://client.elior.app';
  client = 'elior';
} else if (process.env.NEXT_PUBLIC_CLIENT && process.env.NEXT_PUBLIC_CLIENT === 'local') {
  baseUrl = 'http://bo.au';
} else if (process.env.NEXT_PUBLIC_CLIENT && process.env.NEXT_PUBLIC_CLIENT === 'eliorpreprod') {
  baseUrl = 'https://apielior.foound.com';
  logoUrl = `${assetsUrl}/logo/elior.png`;
  icsFile = 'elior.ics';
  clientUrl = 'https://client.elior.app';
  client = 'eliorpreprod';
} else if (process.env.NEXT_PUBLIC_CLIENT && process.env.NEXT_PUBLIC_CLIENT === 'netto') {
  baseUrl = 'https://api.foound.it';
  logoUrl = `${assetsUrl}/logo/netto.png`;
  icsFile = 'nettot.ics';
  clientUrl = 'https://back-netto.menuchic.com';
  client = 'netto';
  jsonUrl = "https://assets.foound.io/netto/staticV4";
  imgUrl = "https://img.foound.io/netto";
  assetsUrl = "https://img.foound.io/netto";
} else if (process.env.NEXT_PUBLIC_CLIENT && process.env.NEXT_PUBLIC_CLIENT === 'nettodev') {
  baseUrl = 'https://apidev.foound.it';
  logoUrl = `${assetsUrl}/logo/netto.png`;
  icsFile = 'nettot.ics';
  clientUrl = 'https://back-netto-dev.menuchic.com';
  client = 'netto';
  jsonUrl = "https://assets.foound.io/netto/staticV4";
  imgUrl = "https://img.foound.io/netto";
  assetsUrl = "https://img.foound.io/netto";
} else if (process.env.NEXT_PUBLIC_CLIENT && process.env.NEXT_PUBLIC_CLIENT === 'dev') {
  baseUrl = 'https://api.menuchic.net';
  logoUrl = `${assetsUrl}/logo/foound.png`;
  icsFile = 'dev.ics';
  clientUrl = 'https://back.menuchic.net';
  client = 'dev';
  jsonUrl = "https://assets.foound.io/dev/staticV4";
  imgUrl = "https://img.foound.io/dev";
  assetsUrl = "https://img.foound.io/dev";
}


let apiURL = baseUrl + "/api/v1";
let statsURL = baseUrl;

let lang = "fr";
let GA = "UA-126725504-2";


let testcd = process.env.TESTCD ? process.env.TESTCD : 'aaaa';

let originalCountryName = {
  ar: "العربية",
  zh: "中国",
  de: "Deutsch",
  en: "English",
  es: "Español",
  fr: "Français",
  it: "Italiano",
  ja: "日本の",
  nl: "Nederlands",
  ru: "русский"
};

const firebaseConfig = {
  apiKey: "AIzaSyDUnyWH7xV3bjwNNCbGmFTGRlWcE-AlhpU",
  projectId: "foound",
  databaseURL: "https://foound.firebaseio.com",
  databaseURL: "https://foound.firebaseio.com",
  storageBucket: "foound.appspot.com"
};

export default {
  apiUrl: apiURL,
  baseUrl: baseUrl,
  assetsUrl: assetsUrl,
  imgUrl: imgUrl,
  statsURL: statsURL,
  clientUrl: clientUrl,
  LANG: lang,
  GA: GA,
  client: client,
  originalCountryName: originalCountryName,
  PUSHER_APP_ID: "720543",
  PUSHER_APP_KEY: "798b384a79fbc6c27c1e",
  PUSHER_APP_CLUSTER: "eu",
  firebaseConfig: firebaseConfig,
  logoUrl: logoUrl,
  icsFile: icsFile,
  testcd: testcd,
  jsonUrl: jsonUrl

};
