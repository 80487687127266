import Cookies from 'js-cookie';
import actionsCommon from "~/redux/common/actions";
import actionsApp from "~/redux/app/actions";

import {
    COOKIE1,
    COOKIE2,
    COOKIE_QRCODE1_DURATION,
    COOKIE_QRCODE2_DURATION
} from "~/src//config/constants";


let _wqrcode1 = false;
let _wqrcode2 = false;

export const checkCookieQrcode = () => {
   
    // console.log('postNativeApp - postNativeApp - postNativeApp');
    if(typeof window === 'object') {
        if( typeof store === 'object' ) {
            const state = store.getState();
            if(state) {
                const {restaurant, common} = state;
                if(restaurant && common.menuType && common.menuType === 'inside') {

                    const qrcode1 = Cookies.get(COOKIE1);
                    const qrcode2 = Cookies.get(COOKIE2);

                    if(_wqrcode1 === false && _wqrcode2 === false) {
                        _wqrcode1 = qrcode1;
                        _wqrcode2 = qrcode2;
                    }

                    //console.log(11111);
                    //console.log(restaurant);
                    //console.log(common.menuType);
                    //console.log(qrcode1);
                    //console.log(qrcode2);
                    //console.log(_wqrcode1);
                    //console.log(_wqrcode2);



                    // on vide le panier si plus de cookie
                    if (typeof qrcode1 === 'undefined' &&  typeof qrcode2 === 'undefined') {
                        
  
                        if(common.cart.products.length > 0) {
                            console.log('RESET CART FROM src/lib/checkCookieQrcode.js');
                            console.log(common.cart.products);
                            window.store.dispatch(actionsCommon.resetCart());
                        }
                        
                    }



                }
            }
        }
    }  

};
  
export default checkCookieQrcode;