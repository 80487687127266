import update from "immutability-helper";
import {
    INIT,
    APP_READY,
    IS_CLIENT_SELECT_DATE,
    SET_PROCESSING_PRODUCTS_INFO,
    SET_VISIT_FROM,
    SET_TABLE_INFO,
    SET_QRCODE_INFO,
    SET_ACTIVE_TAB,
    TESTCD,
    SET_DOMAIN,
    SET_HEADERS,
    SET_CTX,
    SET_URL,
    SET_SWIPE,
    SET_SWIPE_PRODUCT,
    SET_SELECTED_MENU,
    SET_HEADER_HEIGHT,
    SET_COOKIE_MODAL,
    SET_ERROR_DOMAIN,
    SET_IP,
    SET_TIP,
    RESET_TIP,
    SHOW_COOKIE_QRCODE_EXPIRE_ALERT,
    MINI_VIEWER
} from "./constants";
import { HEADER_MIN } from "~/src/config/constants";
import getCookie from "~/src/lib/getCookie";

export const initialState = {
    ready: false,
    activeTab: "information",
    isClientSelectDate: false,
    qrcode: false,
    testcd: 1,
    domain: '',
    headers: false,
    ctx: false,
    url: '',
    swipe: {
        t1: 0,
        t2: 0,
        t3: 0
    },
    swipeProduct: false,
    selectedMenu: false,
    headerHeight: HEADER_MIN,
    //cookieModal: getCookie('cookieConsent'),
    cookieModal: false,
    errorDomain: false,
    ip: false,
    tip: 0,
    cookieQrcodeExpireAlert: false,
    miniViewer: false,
};



export default (state = initialState, action) => {
    switch (action.type) {
        case APP_READY:
            return {
                ...state,
                ready: true
            };


        case SET_HEADER_HEIGHT:
            return {
                ...state,
                headerHeight: action.payload
            };


        case SET_TIP:
            return {
                ...state,
                tip: action.payload
            };

        case RESET_TIP:
            return {
                ...state,
                tip: 0
            };

        case SET_DOMAIN:
            return {
                ...state,
                domain: action.payload
            };

        case SET_IP:
            console.log('SET_IP');
            console.log(action.payload);
            return {
                ...state,
                ip: action.payload
            };  

        case SET_SWIPE:
            return {
                ...state,
                swipe: action.payload
            };

        case SET_SWIPE_PRODUCT:
            return {
                ...state,
                swipeProduct: action.payload
            };

        case MINI_VIEWER:
            return {
                ...state,
                miniViewer: action.payload
            };

            


        case SET_HEADERS:
            return {
                ...state,
                headers: action.payload
            };
        case SET_CTX:
            return {
                ...state,
                ctx: action.payload
            };

        case SET_URL:
            return {
                ...state,
                url: action.payload
            };

        case TESTCD:
            return {
                ...state,
                testcd: action.payload
            };

        case SET_ACTIVE_TAB:

            return {
                ...state,
                activeTab: action.payload
            };

        case SET_TABLE_INFO:
            return {
                ...state,
                tableInfo: action.payload
            };

        case SET_QRCODE_INFO:
            return {
                ...state,
                qrcode: action.payload
            };

        case SET_VISIT_FROM:
            return {
                ...state,
                visitFrom: action.payload
            };

        case SET_COOKIE_MODAL:
            return {
                ...state,
                cookieModal: action.payload
            };

            

        case IS_CLIENT_SELECT_DATE:
            return {
                ...state,
                isClientSelectDate: action.payload
            };

        case SET_PROCESSING_PRODUCTS_INFO:
            return {
                ...state,
                processingProductsInfo: action.payload
            };

        case SET_SELECTED_MENU: {
            return { ...state, selectedMenu: action.payload };
        }

        case SET_ERROR_DOMAIN: {
            return { ...state, errorDomain: action.payload };
        }


        case SHOW_COOKIE_QRCODE_EXPIRE_ALERT: {
            return { ...state, cookieQrcodeExpireAlert: action.payload };
        }
        

        default:
            return state;
    }
};
