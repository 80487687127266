import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

import { BLUE, GREEN, RED } from "~/src/config/constants";

let theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: GREEN
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: BLUE
    }
    // error: will use the default color
  },
  spacing: 8,
  typography: {

    body1: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.2,
        letterSpacing: '0.00938em'
    },
    
    
    body2: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.22,
        letterSpacing: '0.01071em'
    },
    
    subtitle1: {
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.2,
        letterSpacing: '0.00938em'
    },
    
    h6: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 1.2,
        letterSpacing: '0.0075em'
    },
    
    h5: {
        fontSize: '1.125rem',
        fontWeight: 800,
        lineHeight: 1.334,
        letterSpacing: '0em'
    },
    
    h4: {
        fontSize: '1.3rem',
        fontWeight: 800,
        lineHeight: 1.235,
        letterSpacing: '0.00735em'
    },
    
    button: {
        fontSize: '0.875rem',
        fontWeight: 800,
        lineHeight: 1.75,
        letterSpacing: '0.02857em',
        textTransform: 'none'
    }
  }
  /*
  typography: {
    h6: {
      fontSize: "1.25rem",
      "@media (max-width:600px)": {
        fontSize: "1rem"
      }
    },
    subtitle2: {
      fontWeight: "500",
      fontSize: "1.07rem",
      lineHeight: 1.62,
      letterSpacing: "0.00714em"
    }
  }`*/
});

theme = responsiveFontSizes(theme);

export default theme;
