export const postNativeApp = () => {
   
    // console.log('postNativeApp - postNativeApp - postNativeApp');
    if(typeof window === 'object' && typeof window.isNativeApp === 'boolean') {
        
        if( typeof store === 'object' ) {
            const state = store.getState();
            if(state) {
                const {user} = state;
                if(user && user.uuid) {
                    try {
                        window.ReactNativeWebView.postMessage(user.uuid);
                    } catch (err) {
                        console.error(err);
                    }
                }
            }
        }
    }  

};
  
export default postNativeApp;