import update from "immutability-helper";
import { SET_ORDER } from "./constants";

export const initialState = [];

const order = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER: {
        return action.payload;
    }

    default:
      return state;
  }
};

export default order;