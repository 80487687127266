import update from "immutability-helper";
import { SET_ORDER_STATUS } from "./constants";

export const initialState = [];

const orderStatus = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER_STATUS: {
        return action.payload;
    }

    default:
      return state;
  }
};

export default orderStatus;