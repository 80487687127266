import getWidth from "~/src/lib/width";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile as isMobile2
} from "react-device-detect";



export default function isMobile() {

    //return isMobile2;

    
    if (getWidth() < 768) {
        return true;
    }

    return false;
    
}
