
export const checkMaintenance =  (restaurant, ip) =>  {

    console.log(333333);
    console.log(typeof restaurant.info);
    if(!restaurant) {
        return true;
    }

    if(typeof restaurant.info === 'undefined') {
        return true;
    }

    //ip = '89.159.69.36';
    console.log('checkMaintenance');
    console.log(ip);
    console.log(restaurant.info.maintenance);
    console.log(restaurant.info.maintenance_by_client);
    console.log(restaurant.info.authorized_ip);
    

    if(restaurant && restaurant.info && 
        typeof restaurant.info.maintenance === 'number' && restaurant.info.maintenance === 1
    ) {
        return true;
    }

    if(restaurant && restaurant.info && 
        typeof restaurant.info.maintenance_by_client === 'number' && restaurant.info.maintenance_by_client === 1
    ) {

        if(typeof ip === 'string' && ip.length > 5 && typeof restaurant.info.authorized_ip === 'string' && restaurant.info.authorized_ip.length > 5) {

            //console.log('BBBBBB');

            const tmp = restaurant.info.authorized_ip.split(',');
            //console.log(tmp);

            if(tmp.includes(ip)) {
                //console.log('CCCC');
                return false;
            }

        }

        return true;
    }
    return false;
}

export default checkMaintenance;