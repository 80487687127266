//import ReactGA from "react-ga";
import GA4React from "ga-4-react";
import getCookie from "~/src/lib/getCookie";
import checkCookieConsent from "~/src/lib/checkCookieConsent";


function gtag(){
    if(typeof dataLayer !== 'undefined') {
        dataLayer.push(arguments);
    }
}


export const initGA = (ga) => {

    console.log('INIT GA ', ga);
    //ReactGA.initialize(ga);

    //if(checkCookieConsent('ga')) {
        
        const script = document.createElement("script");
        script.src = "https://www.googletagmanager.com/gtag/js?id="+ ga;
        script.async = true;
        document.getElementsByTagName('head')[0].appendChild(script);
    
        window.dataLayer = window.dataLayer || [];
    
        //window.gtag = () => {dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', ga);
            
    //}
}

export const logPageView = (p) => {

    //if(checkCookieConsent('ga')) {
        let path = window.location.pathname;
        if(p) {
            path = p;
        }

        //console.log('LOG PAGE VIEW');
        //console.log(path);

        try {
            gtag('event', 'screen_view', path);
        } catch (err) {
            console.log(err);
        }
    //}
    


    /*
    let path = window.location.pathname;
    if(p) {
        path = p;
    }

    window.ga4react.initialize().then((ga4) => {
        ga4.pageview(p);
        ga4.gtag('event','pageview',path) // or your custom gtag event
        },(err) => {
        console.error(err)
    })
    */

}

export const logOrder = (order) => {

    if(checkCookieConsent('ga')) {
        console.log('ANALYTIC LOG ORDER');
        console.log(order);
        
        //https://developers.google.com/analytics/devguides/collection/ga4/ecommerce#purchase

        try {

            gtag('event', 'purchase', {
                currency: 'EUR',
                transaction_id: order.id,
                value: order.total,
            })
        } catch (err) {
            console.log(err);
        }
    }
}




/*
import ReactGA from "react-ga";

export async function init(ga) {


    if(typeof window.initGa === 'undefined') {
        try {

            ReactGA.initialize(ga);
            window.initGa = 'on';
          } catch (error) {
            console.error("init analytics", error); // eslint-disable-line
          }
    }

}

export async function pageView(p, lang = "fr") {

  try {
    ReactGA.pageview(p);
  } catch (error) {
    console.error("pageview analytics", error); // eslint-disable-line
  }
}
*/
