export const isEqual = (a1, a2) => {
 
  if (a1.id == a2.id) {
    let inl = true;

    if (a1.cartPrice.pricing_value_id !== a2.cartPrice.pricing_value_id) {
      inl = false;
    }

    a1.properties.forEach(function(property) {
      if (property.type === "options") {
        const res = a2.properties.find(
          item =>
            item.optionValueId === property.optionValueId &&
            item.type === property.type
        );
      
        if (!res) {
          inl = false;
        }
      }
    });

    return inl;
  }

  return false;
};

export default isEqual;
