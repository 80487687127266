import {
    SET_USER,
    CHANGE_EMAIL,
} from "./constants";

const actions = {
    setUser: payload => ({
        type: SET_USER,
        payload
    }),

    changeEmail: payload => ({
        type: CHANGE_EMAIL,
        payload
    }),
    
};

export default actions;
