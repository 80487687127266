export const getOptionValue = (restaurant, optionId, optionValueId) => {
  const option = restaurant.options.find(o => o.id === optionId);

  if (option && option.values) {
    const optionValue = option.values.find(o => o.id === optionValueId);
    if (optionValue) {
      return optionValue;
    }
  }

  return false;
};

export default getOptionValue;
