import { combineReducers } from 'redux';
import { routerReducer } from 'connected-next-router';
import app, { initialState as appState } from "~/redux/app/reducer";
import common, { initialState as commonState } from "~/redux/common/reducer";
import restaurant from "~/redux/restaurant/reducer";
import lang from "~/redux/lang/reducer";
import cookie from "~/redux/cookie/reducer";
import menu from "~/redux/menu/reducer";
import order from "~/redux/order/reducer";
import orderStatus from "~/redux/orderStatus/reducer";
import user, { initialState as userState } from "~/redux/user/reducer";


/*
export const reducerState = {
    app: appState,
    common: commonState,
    router: initialRouterState
};

export const reducerState1 = {
    restaurant: restaurantState,
};
*/


export default combineReducers({
    app,
    common,
    lang,
    cookie,
    restaurant,
    order,
    orderStatus,
    user,
    menu,
    router: routerReducer
});

